<template>
    <div>
        <div>
            <breadcrumb
                name="Extended Warranties"
                title="Warranties"
                subtitle="View"
                @onTitleClicked="$router.push(`/extended-warranty`)"
            />
        </div>
        <div>
            <vx-card title="Extended Warranty" class="mb-base">
                <vs-row vs-type="flex">
                    <vs-col
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="12"
                    >
                        <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                            <table class="sm-table">
                                <tr>
                                    <td class="font-semibold p-4">English Name</td>
                                    <td class="p-4">{{item.name_en}}</td>
                                </tr>
                            </table>
                        </div>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="12"
                    >
                        <div class="vx-col-5 flex-1 m-auto" id="account-info-col-2">
                            <table class="sm-table">
                                <tr>
                                    <td class="font-semibold p-4">Arabic Name</td>
                                    <td class="p-4">{{ item.name_ar}}</td>
                                </tr>
                            </table>
                        </div>
                    </vs-col>
                </vs-row>
            </vx-card>

            <vx-card title="Contents" class="mb-base" style="position: relative">
                <div style="width: 100%; margin-top: -40px" align="right">
                    <vs-button
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="$router.push(`/extended-warranty/add-contents/${item.id}`)"
                    >Add New</vs-button>
                </div>

                <vs-table ref="table" :sst="true" style="margin-top: 20px" :data="item.contents">
                    <template slot="thead">
                        <vs-th>English Title</vs-th>
                        <vs-th>Arabic Title</vs-th>
                        <vs-th>Action</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <tbody>
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <string-row :data="tr.name_en" />
                                <string-row :data="tr.name_ar" />
                                <actions-row
                                    @onEditClicked="$router.push(`/extended-warranty/update-content/${tr.id}`)"
                                    @onDeleteClicked="deleteContent(tr.id, indextr)"
                                />
                            </vs-tr>
                        </tbody>
                    </template>
                </vs-table>
            </vx-card>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "../../../components/general/breadcrumb";
import StringRow from "../../../components/table/rows/string-row";
import ActionsRow from "../../../components/table/rows/actions-row";
import ImageRow from "../../../components/table/rows/image-row";

export default {
    name: "show-category",
    components: { ImageRow, ActionsRow, StringRow, Breadcrumb },
    data() {
        return {
            id: "",
            item: ""
        };
    },
    methods: {
        getValue(value) {
            if (value === true) {
                return "True";
            } else if (value === false) {
                return "False";
            }
            return value;
        },
        getCategoryById() {
            this.$vs.loading();
            this.$http
                .get(`/warranties/${this.id}`)
                .then(r => {
                    this.item = r.data.data;
                    this.$vs.loading.close();
                })
                .catch(() => {
                    //console.log({ getCategoryById_error: e });
                });
        },
        deleteContent(id, index) {
            this.$vs.loading();
            this.$http
                .get(`warranties/delete-warranty-item/${id}`)
                .then(r => {
                    console.log({ deleteSpec_response: r });
                    this.$vs.loading.close();
                    this.item.contents.splice(index, 1);
                    this.$vs.notify({
                        title: "Success",
                        color: "success",
                        text: "Content is deleted successfully"
                    });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log({ deleteSpec_error: e });
                    this.$vs.notify({
                        title: "Error",
                        color: "danger",
                        text: "Couldn't delete this Content!"
                    });
                });
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getCategoryById();
    }
};
</script>

<style scoped lang="scss">
</style>
